import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import SVG404 from '../assets/404.svg'
import { Button } from "../components/button"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="content">
      <h4>Opps, this seems to be a broken... </h4>
      <Button link='/' text='Home'/>
    </div>
  </Layout>
)

export default NotFoundPage
